import * as R from 'ramda';
// constants
import * as GC from '../constants';
// features
import PC from '../features/permission/role-permission';
// helpers
import { getTheme } from './theme';
import { getWindowLocale } from './locale';
import { renameKeys } from './array-object';
import { getPropFromObject } from './getter';
import { concatLocationFields } from './location';
import { getConfigValueFromStore } from './common';
import { addEmptyOptionToDropDown } from './config';
import { toTitleCase, createStringFromArray } from './string';
import {
  isValidDate,
  addDateTime,
  convertTimeToFormat,
  addDateTimeWithFormat,
  getTimeByConfigFormat,
  createLocalDateTimeFormat,
  getDateTimeFormatFromConfig,
  createTodayLocalDateTimeString,
  convertInstanceToDefaultDateFormat,
  checkAndConvertStringToFormattedDate,
  createLocalDateTimeFromInstanceOrISOString,
} from './date';
import {
  ifElse,
  isFalse,
  isString,
  isNotNil,
  isNilOrEmpty,
  isFirstItemObject,
  isFirstItemString,
  isNotNilAndNotEmpty,
} from './helpers';
//////////////////////////////////////////////////

const isLoadTypeClo = (data: any) => {
  if (isNilOrEmpty(data)) return false;

  if (isString(data)) return R.equals(R.toUpper(data), GC.LOAD_TYPE_CLO);

  return R.propEq(GC.LOAD_TYPE_CLO, GC.FIELD_LOAD_TYPE, data);
};

const isLoadTypeTel = (data: any) => {
  if (isNilOrEmpty(data)) return false;

  if (isString(data)) return R.equals(R.toUpper(data), GC.LOAD_TYPE_TEL);

  return R.propEq(GC.LOAD_TYPE_TEL, GC.FIELD_LOAD_TYPE, data);
};

const isEventTypeDrop = R.equals(GC.EVENT_TYPE_DROP);

const isEventTypePickup = R.equals(GC.EVENT_TYPE_PICKUP);

const isStopTypeTerminal = R.equals(GC.STOP_TYPE_TERMINAL);

const isStopDrop = (stop: Object) => R.propEq(GC.EVENT_TYPE_DROP, GC.FIELD_EVENT_TYPE, stop);

const isStopPickup = (stop: Object) => R.propEq(GC.EVENT_TYPE_PICKUP, GC.FIELD_EVENT_TYPE, stop);

const getDistancePropByLoadType = (type: string) => (
  ifElse(
    R.equals(R.toUpper(type), GC.LOAD_TYPE_CLO),
    GC.FIELD_DISTANCE_CLO,
    GC.FIELD_DISTANCE_TEL,
  )
);

const getEventIndexPropByLoadType = (type: string) => (
  ifElse(
    R.equals(R.toUpper(type), GC.LOAD_TYPE_CLO),
    GC.FIELD_CLO_EVENT_INDEX,
    GC.FIELD_TEL_EVENT_INDEX,
  )
);

const getLoadColors = (type: string = '') => {
  const white = getTheme('colors.white');
  const black = getTheme('colors.light.black');
  const green = getTheme('colors.light.green');
  const darkRed = getTheme('colors.light.darkRed');
  const darkGrey = getTheme('colors.light.darkGrey');
  const teaGreen = getTheme('colors.light.teaGreen');
  const sweetPink = getTheme('colors.light.sweetPink');
  const typeUppercase = R.toUpper(type);

  return {
    clo: {
      color: black,
      iconColor: darkRed,
      headerBg: ifElse(R.equals(typeUppercase, GC.EVENT_TYPE_PICKUP), teaGreen, green),
      headerColor: ifElse(R.equals(typeUppercase, GC.EVENT_TYPE_PICKUP), black, white),
    },
    tel: {
      color: white,
      iconColor: white,
      headerBg: sweetPink,
      headerColor: ifElse(R.equals(typeUppercase, GC.EVENT_TYPE_PICKUP), black, white),
    },
    terminal: {
      color: white,
      iconColor: white,
      headerBg: ifElse(R.equals(typeUppercase, GC.EVENT_TYPE_PICKUP), darkGrey, black),
      headerColor: ifElse(R.equals(typeUppercase, GC.EVENT_TYPE_PICKUP), black, white),
    },
  };
};

const renderStopColor = (stop: Object) => {
  const entityName = ifElse(
    R.isNil(stop.cloGuid),
    ifElse(R.equals(stop.stopType, GC.STOP_TYPE_TERMINAL), 'terminal', GC.FIELD_TEL),
    GC.FIELD_CLO,
  );

  return R.prop(entityName, getLoadColors(stop.eventType));
};

const getItemsFromEventsUniqByGuid = (events: Object) => R.compose(
  R.uniqBy(R.prop(GC.FIELD_GUID)),
  R.reduce(R.concat, []),
  R.map((event: Object) => R.prop(GC.FIELD_LOAD_ITEMS, event)),
)(events);

const getStopDatesInfo = (fields: Array, stop: Object, joiner: string) => {
  const format = getDateTimeFormatFromConfig();

  const values = R.compose(
    R.map((date: Array) => checkAndConvertStringToFormattedDate(date, format)),
    R.values,
    R.filter(isNotNilAndNotEmpty),
    R.pick(fields),
  )(stop);

  return createStringFromArray(values, joiner);
};

const getStopAppointmentDateTimeInfo = (fields: Array, stop: Object, joiner: string) => {
  const dateFormat = getDateTimeFormatFromConfig('date');
  const timeFormat = getDateTimeFormatFromConfig('time');
  const values = R.values(R.pick(fields, stop));

  let dateValue = R.head(values);

  if (isNotNilAndNotEmpty(dateValue)) {
    dateValue = checkAndConvertStringToFormattedDate(dateValue, dateFormat);
  }

  const timeValues = R.compose(
    R.map((time: Array) => checkAndConvertStringToFormattedDate(
      `${convertInstanceToDefaultDateFormat()} ${time}`,
      timeFormat,
    )),
    R.filter(isNotNilAndNotEmpty),
    R.tail,
  )(values);

  return createStringFromArray([dateValue, ...timeValues], joiner);
};

const getStopFieldsInfo = (fields: Array, stop: Object, joiner: string) => {
  const values = R.values(R.pick(fields, stop));

  return createStringFromArray(values, joiner);
};

const getStopNumberTitle = ({ eventType }: Object) => ifElse(
  isEventTypePickup(eventType),
  getWindowLocale('titles:pickup-number', 'Pickup Number'),
  getWindowLocale('titles:drop-number', 'Drop Number'),
);

const getBranchPickupDateTimeDefaultValues = (
  configStore: Object,
  userSettings: Object,
) => {
  // TODO: uncomment after adding support formats from profile or configs
  // const dateFormat = R.pathOr(defauldDateFormat, ['dateFormat'], userSettings);
  const dateFormat = GC.DEFAULT_DATE_FORMAT;

  const localDateTimeFormat = createLocalDateTimeFormat(dateFormat);

  const pickupEarlyTimeValue = getConfigValueFromStore(
    GC.CLO_GENERAL_PICKUP_EARLY_TIME,
    configStore,
  );

  const pickupIntervalValue = getConfigValueFromStore(
    GC.CLO_GENERAL_PICKUP_INTERVAL,
    configStore,
  );

  const values = {};

  if (R.isEmpty(pickupEarlyTimeValue)) {
    return values;
  }

  const early = createTodayLocalDateTimeString(dateFormat, pickupEarlyTimeValue);
  const eventEarlyDate = new Date(early);

  let eventLateDate = '';

  if (isValidDate(eventEarlyDate)) {
    values.eventEarlyDate = createLocalDateTimeFromInstanceOrISOString(eventEarlyDate, localDateTimeFormat);
    eventLateDate = addDateTime(eventEarlyDate, 15);
  }

  if (isValidDate(eventLateDate)) {
    values.eventLateDate = createLocalDateTimeFromInstanceOrISOString(eventLateDate, localDateTimeFormat);
  }

  return values;
};

const getBranchDropDateTimeDefaultValues = (
  configStore: Object,
  userSettings: Object,
) => {
  // TODO: uncomment after adding support formats from profile or configs
  // const dateFormat = R.pathOr(defauldDateFormat, ['dateFormat'], userSettings);
  const dateFormat = GC.DEFAULT_DATE_FORMAT;

  const localDateTimeFormat = createLocalDateTimeFormat(dateFormat);

  const dropEarlyTimeValue = getConfigValueFromStore(
    GC.CLO_GENERAL_DROP_EARLY_TIME, configStore,
  );

  const dropIntervalValue = getConfigValueFromStore(
    GC.CLO_GENERAL_DROP_INTERVAL, configStore,
  );

  const stopInterval = getConfigValueFromStore(
    GC.CLO_GENERAL_EVENTS_INTERVAL, configStore,
  );

  const values = {};

  if (R.isEmpty(dropEarlyTimeValue)) {
    return values;
  }

  const early = createTodayLocalDateTimeString(dateFormat, dropEarlyTimeValue);
  const eventEarlyDate = new Date(early);

  let eventLateDate = '';

  if (isValidDate(eventEarlyDate)) {
    values.eventEarlyDate = createLocalDateTimeFromInstanceOrISOString(eventEarlyDate, localDateTimeFormat);

    values.eventEarlyDate = addDateTimeWithFormat(
      eventEarlyDate,
      R.or(stopInterval, 0),
      'days',
      localDateTimeFormat,
    );

    eventLateDate = addDateTime(eventEarlyDate, dropIntervalValue);
  }

  if (isValidDate(eventLateDate)) {
    values.eventLateDate = createLocalDateTimeFromInstanceOrISOString(eventLateDate, localDateTimeFormat);
  }

  return values;
};

const getBranchNewStopDateTimeDefaultValues = (stopType: string, store: Object) => {
  const { stops, branchConfigs } = store;

  const prevEventLateDate = R.path([R.length(R.values(stops)), 'formData', GC.FIELD_LOAD_EVENT_LATE_DATE], stops);

  if (isNilOrEmpty(prevEventLateDate)) {
    return {};
  }

  const dateFormat = GC.DEFAULT_DATE_FORMAT;
  const localDateTimeFormat = createLocalDateTimeFormat(dateFormat);

  const dropIntervalValue = getConfigValueFromStore(
    GC.CLO_GENERAL_DROP_INTERVAL, branchConfigs,
  );

  const pickupIntervalValue = getConfigValueFromStore(
    GC.CLO_GENERAL_PICKUP_INTERVAL,
    branchConfigs,
  );

  const stopInterval = getConfigValueFromStore(
    GC.CLO_GENERAL_EVENTS_INTERVAL, branchConfigs,
  );

  const values = {};

  const eventEarlyDate = new Date(prevEventLateDate);

  let eventLateDate = '';

  if (isValidDate(eventEarlyDate)) {
    values.eventEarlyDate = addDateTimeWithFormat(
      eventEarlyDate,
      R.or(stopInterval, 0),
      'days',
      localDateTimeFormat,
    );

    const intervalValue = ifElse(
      R.equals(stopType, GC.EVENT_TYPE_PICKUP),
      R.or(pickupIntervalValue, 1),
      R.or(dropIntervalValue, 1),
    );

    eventLateDate = addDateTime(eventEarlyDate, intervalValue);
  }

  if (isValidDate(eventLateDate)) {
    values.eventLateDate = createLocalDateTimeFromInstanceOrISOString(eventLateDate, localDateTimeFormat);
  }

  return values;
};

const mapTelEventsForMap = (events: Array) => R.compose(
  R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX)),
  R.values,
  R.filter(isNotNil),
  R.map((stop: Object) => {
    const lat = R.path([GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LATITUDE], stop);
    const lng = R.path([GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LONGITUDE], stop);
    if (R.or(R.isNil(lat), R.isNil(lng))) return null;
    return {
      ...stop,
      title: `${toTitleCase(stop.eventType)} ${stop[GC.FIELD_TEL_EVENT_INDEX]}`,
      latLng: { lat, lng },
    };
  }),
)(events);

const keysMap = {
  distance: GC.FIELD_DISTANCE_SYSTEM,
  unit: GC.FIELD_DISTANCE_SYSTEM_UOM,
};

const emptyDistance = {
  [GC.FIELD_DISTANCE_SYSTEM]: null,
  [GC.FIELD_DISTANCE_SYSTEM_UOM]: null,
};

const mapStopsWithDistances = (loadType: string, sortedStops: Array, distanceRes: Object) => {
  const distancesArr = R.compose(
    R.append(emptyDistance),
    R.map((item: Object) => renameKeys(keysMap, item)),
    R.map((item: Object) => R.pick(['distance', 'unit'], item)),
    R.prop('stopResults'),
  )(distanceRes);

  const zippedStops = R.zipWith((stop: Object, distance: Object) => {
    const distanceToNextStop = R.prop(getDistancePropByLoadType(loadType), stop);
    const newDistanceToNextStop = R.mergeRight(distanceToNextStop, distance);

    return R.assoc(getDistancePropByLoadType(loadType), newDistanceToNextStop, stop);
  }, sortedStops, distancesArr);

  return R.indexBy(R.prop(GC.FIELD_ORDER), zippedStops);
};

const getLoadStopsGeodata = R.reduce((acc: Array, stop: Object) => {
  if (R.isNil(acc)) return null;

  const latitude = R.pathOr(null, ['location', GC.FIELD_LATITUDE], stop);
  const longitude = R.pathOr(null, ['location', GC.FIELD_LONGITUDE], stop);

  return ifElse(
    R.and(isNotNil(latitude), isNotNil(longitude)),
    R.append({ latitude, longitude }, acc),
    null,
  );
}, []);

const getDataFromEvents = (loadType: string, events: Array) => {
  const stopsWithOrder = R.map(
    (event: Object) => {
      const orderProp = getEventIndexPropByLoadType(loadType);
      const order = R.prop(orderProp, event);

      return R.assoc(GC.FIELD_ORDER, order, event);
    },
    events,
  );

  const sortedStops = R.sortBy(R.prop(GC.FIELD_ORDER), stopsWithOrder);
  const firstStop = R.head(sortedStops);
  const lastStop = R.last(sortedStops);
  const pickups = R.filter((stop: Object) => R.propEq(GC.EVENT_TYPE_PICKUP, GC.FIELD_EVENT_TYPE, stop), sortedStops);
  const drops = R.filter((stop: Object) => R.propEq(GC.EVENT_TYPE_DROP, GC.FIELD_EVENT_TYPE, stop), sortedStops);

  const pickedItems = R.reduce(
    (acc: Array, pickup: Array) => R.concat(acc, R.pathOr([], [GC.FIELD_LOAD_ITEMS], pickup)),
    [], pickups,
  );

  const droppedItems = R.reduce(
    (acc: Array, drop: Array) => R.concat(acc, R.pathOr([], [GC.FIELD_LOAD_ITEMS], drop)),
    [], drops,
  );

  const pickedItemsInternalIds = R.map((item: Object) => R.prop(GC.FIELD_ITEM_INTERNAL_ID, item), pickedItems);
  const droppedItemInternalIds = droppedItems;

  return {
    lastStop,
    firstStop,
    sortedStops,
    pickedItems,
    droppedItems,
    pickedItemsInternalIds,
    droppedItemInternalIds,
  };
};

const removeCLODistanceFromNewTelEvents = R.map(
  R.when(
    R.propEq(true, 'isNew'),
    R.omit(GC.FIELD_DISTANCE_CLO),
  ),
);

const removeCLODistanceFromTelEvents = R.map(
  R.when(
    R.propEq(false, GC.FIELD_CLO_EVENT),
    R.omit(GC.FIELD_DISTANCE_CLO),
  ),
);

const getStopData = (stop: Object, fromTel: boolean = false) => {
  if (R.isNil(stop)) return null;

  const eventIndex = R.prop(
    ifElse(fromTel, GC.FIELD_TEL_EVENT_INDEX, GC.FIELD_CLO_EVENT_INDEX),
    stop,
  );

  return `
    ${toTitleCase(stop.eventType)}
    ${eventIndex}:
    ${concatLocationFields(stop.location)}`;
};

const createStopOptions = (stops: Array, condition: Object) => (
  R.map((stop: Object) => (
    { label: getStopData(stop, condition), value: stop.guid }
  ), stops)
);

const getStopPointsDataAccForDistanceCalculator = (event: Object, acc: Array) => {
  const latitude = R.pathOr(null, [GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LATITUDE], event);
  const longitude = R.pathOr(null, [GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LONGITUDE], event);
  const city = R.pathOr(null, [GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_CITY], event);
  const state = R.pathOr(null, [GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_STATE], event);
  const cityState = { city, state };

  return ifElse(
    R.and(isNotNil(latitude), isNotNil(longitude)),
    R.append({ ...cityState, latitude, longitude }, acc),
    ifElse(
      R.and(isNotNil(city), isNotNil(state)),
      R.append(cityState, acc),
      null,
    ),
  );
};

const hasLoadEventsContainer = (events: Array) => R.compose(
  R.gt(R.__, 0),
  R.length,
  R.reject((item: Object) => isNilOrEmpty(getPropFromObject(GC.FIELD_STOP_PICKED_UP_CONTAINERS, item))),
)(R.or(events, []));

const hasEventContainers = (event: Object) => R.or(
  isNotNilAndNotEmpty(R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS, event)),
  isNotNilAndNotEmpty(R.prop(GC.FIELD_STOP_DROPPED_CONTAINER_IDS, event)),
);

const getContainersFromEventsUniqByGuid = R.compose(
  R.uniqBy(R.prop(GC.FIELD_GUID)),
  R.flatten,
  R.map(({ droppedContainers, pickedUpContainers }: Object) => R.concat(
    R.or(droppedContainers, []),
    R.or(pickedUpContainers, []),
  )),
);

const setEventEarlyTimeFromEarlyDate = (values: Object) => {
  if (isNilOrEmpty(values)) return values;

  const format = getDateTimeFormatFromConfig('time');
  const eventDate = R.path([GC.FIELD_LOAD_EVENT_EARLY_DATE], values);

  if (isValidDate(eventDate)) {
    const earlyTime = R.path([GC.FIELD_LOAD_EVENT_EARLY_TIME], values);
    const earlyTimeToUse = R.or(earlyTime, createLocalDateTimeFromInstanceOrISOString(eventDate, format));

    return R.assoc(
      GC.FIELD_LOAD_EVENT_EARLY_TIME,
      earlyTimeToUse,
      values,
    );
  }

  return values;
};

const setEventEarlyTimeFromEarlyDate2 = (values: Object) => {
  if (isNilOrEmpty(values)) return values;

  const eventDate = R.path([GC.FIELD_LOAD_EVENT_EARLY_DATE], values);

  if (isValidDate(eventDate)) {
    return {
      ...values,
      [GC.FIELD_LOAD_EVENT_EARLY_DATE]: null,
      [GC.FIELD_LOAD_EVENT_EARLY_TIME]: createLocalDateTimeFromInstanceOrISOString(eventDate, 'LT'),
    };
  }

  return values;
};

const setEventLateTimeFromLateDate = (values: Object) => {
  if (isNilOrEmpty(values)) return values;

  const format = getDateTimeFormatFromConfig('time');
  const eventDate = R.path([GC.FIELD_LOAD_EVENT_LATE_DATE], values);

  if (isValidDate(eventDate)) {
    const lateTime = R.path([GC.FIELD_LOAD_EVENT_LATE_TIME], values);
    const lateTimeToUse = R.or(lateTime, createLocalDateTimeFromInstanceOrISOString(eventDate, format));

    return R.assoc(
      GC.FIELD_LOAD_EVENT_LATE_TIME,
      lateTimeToUse,
      values,
    );
  }

  return values;
};

const setEventLateTimeFromLateDate2 = (values: Object) => {
  if (isNilOrEmpty(values)) return values;

  const eventDate = R.path([GC.FIELD_LOAD_EVENT_LATE_DATE], values);

  if (isValidDate(eventDate)) {
    return {
      ...values,
      [GC.FIELD_LOAD_EVENT_LATE_DATE]: null,
      [GC.FIELD_LOAD_EVENT_LATE_TIME]: createLocalDateTimeFromInstanceOrISOString(eventDate, 'LT'),
    };
  }

  return values;
};

const getFormattedDate = (propName: string, values: Object, format: string) => {
  let value = R.path([propName], values);

  if (isValidDate(value)) {
    value = createLocalDateTimeFromInstanceOrISOString(value, format);
  }

  return value;
};

const getFormattedTime = (propName: string, values: Object, format: string) => {
  const time = R.path([propName], values);

  if (isNilOrEmpty(time)) return time;

  const date = `${convertInstanceToDefaultDateFormat(new Date())} ${time}`;

  return createLocalDateTimeFromInstanceOrISOString(date, format);
};

const formatEventDates = (values: Object) => {
  const format = getDateTimeFormatFromConfig('dateTime');
  const timeFormat = getDateTimeFormatFromConfig('time');
  const eventLateDate = getFormattedDate(GC.FIELD_LOAD_EVENT_LATE_DATE, values, format);
  const eventEarlyDate = getFormattedDate(GC.FIELD_LOAD_EVENT_EARLY_DATE, values, format);
  const appointmentLateTime = getFormattedTime(GC.FIELD_LOAD_APPOINTMENT_LATE_TIME, values, timeFormat);
  const appointmentEarlyTime = getFormattedTime(GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME, values, timeFormat);

  return {
    ...values,
    eventLateDate,
    eventEarlyDate,
    appointmentLateTime,
    appointmentEarlyTime,
    [GC.FIELD_LATE_DATE]: eventLateDate,
    [GC.FIELD_EARLY_DATE]: eventEarlyDate,
  };
};

const getDateTimeByConfigFormat = (date: string) => {
  const format = getDateTimeFormatFromConfig('dateTime');

  if (isValidDate(date)) {
    return createLocalDateTimeFromInstanceOrISOString(date, format);
  }

  return date;
};

const createAppointmentDateTimeString = (stop: Object) => {
  if (isNilOrEmpty(R.prop(GC.FIELD_LOAD_APPOINTMENT_DATE, stop))) return null;

  const format = getDateTimeFormatFromConfig('time');
  const time = R.compose(
    R.join(' - '),
    R.map((time: Object) => {
      let timeToUse = time;

      if (R.is(Object, time)) timeToUse = createLocalDateTimeFromInstanceOrISOString(time, GC.DEFAULT_TIME_FORMAT);

      const date = `${convertInstanceToDefaultDateFormat()} ${timeToUse}`;

      return createLocalDateTimeFromInstanceOrISOString(date, format);
    }),
    R.filter((time: string) => isNotNilAndNotEmpty(time)),
    R.values(),
    R.pick([
      GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME,
      GC.FIELD_LOAD_APPOINTMENT_LATE_TIME,
    ]),
  )(stop);

  const str = `${R.prop(GC.FIELD_LOAD_APPOINTMENT_DATE, stop)} ${time}`;

  return getDateTimeByConfigFormat(str);
};

const setEventEarlyLateTimeFromEarlyLateDate = (values: Object) => {
  if (isNilOrEmpty(values)) return values;

  return formatEventDates(setEventEarlyTimeFromEarlyDate(setEventLateTimeFromLateDate(values)));
};

const setEventEarlyLateTimeFromEarlyLateDate2 = (values: Object) => {
  if (isNilOrEmpty(values)) return values;

  return setEventEarlyTimeFromEarlyDate2(setEventLateTimeFromLateDate2(values));
};

const setEventInitialDroppedContainers = (values: Object) => {
  const droppedContainers = R.pathOr([], [GC.FIELD_STOP_DROPPED_CONTAINERS], values);

  if (isFirstItemString(droppedContainers)) return values;

  if (isFirstItemObject(droppedContainers)) {
    return R.assoc(
      GC.FIELD_STOP_DROPPED_CONTAINERS,
      R.map(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID), droppedContainers),
      values,
    );
  }

  return values;
};

const getLoadEventOptions = (events: Array, fromTel: boolean = false) => R.map(
  (event: Object) => {
    const { guid, status } = event;

    return {
      [GC.FIELD_VALUE]: guid,
      [GC.FIELD_LABEL]: `${getStopData(event, fromTel)} (${toTitleCase(status)})`,
    };
  },
  events,
);

const createEventIntegrationString = (event: Object, joiner: any = ',') => {
  const { location } = event;

  const { integrationId, integrationType } = location;

  if (R.or(isNilOrEmpty(integrationId), isNilOrEmpty(integrationType))) return null;

  return `${integrationType}${joiner} ${integrationId}`;
};

const getStatusOptionsWithoutEmptyOption = (loadType: string) => R.compose(
  R.values,
  R.mapObjIndexed((value: string, key: string) => ({
    value: key,
    label: getWindowLocale(value, key),
  })),
  R.pick(GC.loadStatusesMap[loadType]),
)(GC.statusLocaleMap);

const getStatusOptions = (loadType: string, addEmptyOption: boolean = true) => {
  const options = getStatusOptionsWithoutEmptyOption(loadType);

  if (isFalse(addEmptyOption)) return options;

  return addEmptyOptionToDropDown(options);
};

// CLO actions
const telReadWritePermissions = [PC.TEL_READ, PC.TEL_WRITE];
const createTelAction = {
  bgColor: 'colors.dark.blue',
  value: GC.CREATE_TEL_ACTION,
  permissions: telReadWritePermissions,
  locale: ['actions:create-tel', 'Create TEL'],
};
const openInRBAction = {
  bgColor: 'colors.dark.blue',
  permissions: [PC.TEL_WRITE],
  value: GC.OPEN_IN_ROUTE_BUILDER_ACTION,
  locale: ['actions:go-to-route-builder', 'Go To Route Builder'],
};
const cancelCloAction = {
  bgColor: 'colors.dark.blue',
  value: GC.CANCEL_CLO_ACTION,
  permissions: [PC.CLO_WRITE],
  locale: ['actions:cancel', 'Cancel'],
};
const editCloRateAction = {
  bgColor: 'colors.dark.blue',
  value: GC.EDIT_CLO_RATE_ACTION,
  permissions: [PC.CLO_RATE_WRITE],
  locale: ['titles:edit-rate', 'Edit Rate'],
};
const addCloDocumentAction = {
  bgColor: 'colors.dark.blue',
  value: GC.ADD_DOCUMENT_ACTION,
  permissions: [PC.CLO_DOCUMENT_WRITE],
  locale: ['titles:add-documents', 'Add Documents'],
};
const addCloDocumentPodAction = {
  bgColor: 'colors.dark.blue',
  value: GC.ADD_DOCUMENT_POD_ACTION,
  permissions: [PC.CLO_DOCUMENT_WRITE],
  locale: ['titles:add-document/POD', 'Add Document/POD'],
};
const createCloInvoiceAction = {
  bgColor: 'colors.dark.blue',
  value: GC.CREATE_CUSTOMER_INVOICE_ACTION,
  locale: ['titles:add-customer-invoice', 'Add Customer Invoice'],
  permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
};
const restoreCloAction = {
  bgColor: 'colors.dark.blue',
  permissions: [PC.CLO_WRITE],
  value: GC.RESTORE_CLO_ACTION,
  locale: ['actions:restore', 'Restore'],
};
const duplicateOrderAction = {
  bgColor: 'colors.dark.blue',
  value: GC.DUPLICATE_ORDER_ACTION,
  permissions: [PC.CLO_DUPLICATE_EXECUTE],
  notHasPermissions: [PC.ROLE_TYPE_CUSTOMER],
  locale: ['actions:duplicate-clo', 'Duplicate CLO'],
};
const unquoteCloAction = {
  bgColor: 'colors.dark.blue',
  permissions: [PC.CLO_WRITE],
  value: GC.UNQUOTE_CLO_ACTION,
  locale: ['actions:unquote', 'Unquote'],
};
const sendCloToExternalSystemAction = {
  bgColor: 'colors.dark.blue',
  permissions: [PC.CLO_WRITE],
  value: GC.SEND_CLO_TO_EXTERNAL_SYSTEM_ACTION,
  locale: ['titles:send-to-external-system', 'Send to External System'],
};

const cloStatusActionsMap = {
  [GC.LOAD_STATUS_QUOTE]: [
    unquoteCloAction,
    editCloRateAction,
    cancelCloAction,
    openInRBAction,
    duplicateOrderAction,
  ],
  [GC.LOAD_STATUS_UNSCHEDULED]: [
    createTelAction,
    editCloRateAction,
    cancelCloAction,
    openInRBAction,
    duplicateOrderAction,
  ],
  [GC.LOAD_STATUS_PLANNED]: [
    editCloRateAction,
    cancelCloAction,
    openInRBAction,
    duplicateOrderAction,
  ],
  [GC.LOAD_STATUS_BOOKED_STATUS]: [
    cancelCloAction,
    openInRBAction,
    duplicateOrderAction,
    sendCloToExternalSystemAction,
  ],
  [GC.LOAD_STATUS_IN_TRANSIT]: [
    addCloDocumentAction,
    createCloInvoiceAction,
    openInRBAction,
    duplicateOrderAction,
    sendCloToExternalSystemAction,
  ],
  [GC.LOAD_STATUS_DELIVERED]: [
    addCloDocumentPodAction,
    createCloInvoiceAction,
    openInRBAction,
    duplicateOrderAction,
    sendCloToExternalSystemAction,
  ],
  [GC.LOAD_STATUS_CANCELED]: [
    restoreCloAction,
    duplicateOrderAction,
  ],
};

// TEL actions
const addTelDocumentAction = {
  bgColor: 'colors.dark.blue',
  value: GC.ADD_DOCUMENT_ACTION,
  permissions: [PC.TEL_DOCUMENT_WRITE],
  locale: ['titles:add-documents', 'Add Documents'],
};
const addTelDocumentPopAction = {
  bgColor: 'colors.dark.blue',
  value: GC.ADD_DOCUMENT_POP_ACTION,
  permissions: [PC.TEL_DOCUMENT_WRITE],
  locale: ['titles:add-document-pop', 'Add Document/POP'],
};
const addTelDocumentPodAction = {
  bgColor: 'colors.dark.blue',
  value: GC.ADD_DOCUMENT_POD_ACTION,
  permissions: [PC.TEL_DOCUMENT_WRITE],
  locale: ['titles:add-document-pod', 'Add Document/POD'],
};
const addDriverRateAction = {
  bgColor: 'colors.dark.blue',
  value: GC.ADD_DRIVER_RATE_ACTION,
  permissions: [PC.FLEET_RATE_WRITE],
  locale: ['titles:add-driver-rate', 'Add Driver Rate'],
};
const addCarrierRateAction = {
  bgColor: 'colors.dark.blue',
  value: GC.ADD_CARRIER_RATE_ACTION,
  permissions: [PC.CARRIER_RATE_WRITE],
  locale: ['titles:add-carrier-rate', 'Add Carrier Rate'],
};
const editDriverCarrierRateAction = {
  bgColor: 'colors.dark.blue',
  value: GC.EDIT_DRIVER_CARRIER_RATE_ACTION,
  locale: ['titles:edit-rate', 'Edit Rate'],
  permissions: [PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE, PC.ROLE_TYPE_CARRIER],
};
const sendQuoteRequest = {
  bgColor: 'colors.dark.blue',
  value: GC.SEND_QUOTE_REQUEST_ACTION,
  permissions: [PC.CARRIER_RATE_WRITE],
  locale: ['titles:send-carriers-bids', 'Send Carriers Bids'],
};
const dispatchLoadAction = {
  bgColor: 'colors.dark.blue',
  value: GC.DISPATCH_LOAD_ACTION,
  locale: ['titles:dispatch', 'Dispatch'],
  permissions: [PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE],
};
const acceptLoadAction = {
  value: GC.ACCEPT_LOAD_ACTION,
  bgColor: 'colors.light.green',
  permissions: [PC.DISPATCH_ACCEPT_EXECUTE],
  locale: ['titles:accept-load', 'Accept Load'],
};
const declineLoadAction = {
  value: GC.DECLINE_LOAD_ACTION,
  bgColor: 'colors.light.mainRed',
  permissions: [PC.DISPATCH_ACCEPT_EXECUTE],
  locale: ['titles:decline-load', 'Decline Load'],
};
const cancelDispatchedLoadAction = {
  bgColor: 'colors.dark.blue',
  value: GC.CANCEL_DISPATCHED_LOAD_ACTION,
  locale: ['titles:cancel-dispatched', 'Cancel Dispatched'],
  permissions: [PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE],
};
const redispatchLoadAction = {
  bgColor: 'colors.dark.blue',
  value: GC.RE_DISPATCH_LOAD_ACTION,
  locale: ['titles:redispatch', 'Redispatch'],
  permissions: [PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE],
};
const callStatusCheckAction = {
  bgColor: 'colors.dark.blue',
  permissions: [PC.TEL_WRITE],
  value: GC.CALL_STATUS_CHECK_ACTION,
  locale: ['actions:status-check', 'Call Status Check'],
};
const checkInAction = {
  value: GC.CHECK_IN_ACTION,
  bgColor: 'colors.dark.blue',
  locale: ['titles:check-in', 'Check In'],
  permissions: [PC.TEL_STATUS_MESSAGE_WRITE],
};
const completeAction = {
  value: GC.COMPLETE_ACTION,
  bgColor: 'colors.dark.blue',
  permissions: [PC.TEL_STATUS_MESSAGE_WRITE],
  locale: ['titles:complete-stop', 'Complete Stop'],
};
const addAppointmentAction = {
  permissions: null,
  bgColor: 'colors.dark.blue',
  value: GC.ADD_APPOINTMENT_ACTION,
  notHasPermissions: [PC.ROLE_TYPE_CARRIER],
  locale: ['titles:add-appointment', 'Add Appointment'],
};
const addDriverCarrierInvoiceAction = {
  bgColor: 'colors.dark.blue',
  value: GC.ADD_DRIVER_CARRIER_INVOICE,
  permissions: [PC.TEL_FLEET_INVOICE_WRITE],
  locale: ['titles:add-invoice', 'Add Invoice'],
};
const sendToExternalSystemAction = {
  bgColor: 'colors.dark.blue',
  permissions: [PC.TEL_WRITE],
  value: GC.SEND_TO_EXTERNAL_SYSTEM_ACTION,
  locale: ['titles:send-to-external-system', 'Send to External System'],
};
const applyOrderRateUpliftAction = {
  bgColor: 'colors.dark.blue',
  permissions: [PC.TEL_WRITE],
  value: GC.APPLY_ORDER_RATE_UPLIFT_ACTION,
  locale: ['titles:apply-order-rate-uplift', 'Apply Order Rate Uplift'],
};
const sendUpdateAction = {
  bgColor: 'colors.dark.blue',
  value: GC.SEND_UPDATE_EDI_OR_API_ACTION,
  locale: ['titles:send-update', 'Send Update'],
  permissions: [PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE],
};
const getIntegrationDocumentsAction = {
  bgColor: 'colors.dark.blue',
  permissions: [PC.TEL_DOCUMENT_WRITE],
  value: GC.GET_INTEGRATION_DOCUMENTS_ACTION,
  locale: ['titles:get-vendor-documents', 'Get Vendor Documents'],
};
const sendToCrossBorderIntegrationAction = {
  bgColor: 'colors.dark.blue',
  permissions: [PC.TEL_WRITE],
  value: GC.SEND_TO_CROSS_BORDER_INTEGRATION_ACTION,
  locale: ['titles:send-to-cross-border', 'Send To Cross Border'],
};

const telStatusActionsMap = {
  [GC.LOAD_STATUS_CANCELED]: [
    openInRBAction,
    addDriverCarrierInvoiceAction,
    sendToCrossBorderIntegrationAction,
  ],
  [GC.LOAD_STATUS_PLANNED]: [
    addDriverRateAction,
    addCarrierRateAction,
    openInRBAction,
    sendQuoteRequest,
    sendToExternalSystemAction,
    sendToCrossBorderIntegrationAction,
  ],
  [GC.LOAD_STATUS_RATED]: [
    addDriverRateAction,
    addCarrierRateAction,
    editDriverCarrierRateAction,
    dispatchLoadAction,
    openInRBAction,
    sendQuoteRequest,
    sendToExternalSystemAction,
    applyOrderRateUpliftAction,
    sendToCrossBorderIntegrationAction,
  ],
  [GC.LOAD_STATUS_DISPATCHED]: [
    acceptLoadAction,
    declineLoadAction,
    cancelDispatchedLoadAction,
    openInRBAction,
    sendToExternalSystemAction,
    applyOrderRateUpliftAction,
    sendToCrossBorderIntegrationAction,
  ],
  [GC.LOAD_STATUS_DISPATCH_EXPIRED]: [
    addDriverRateAction,
    addCarrierRateAction,
    editDriverCarrierRateAction,
    redispatchLoadAction,
    openInRBAction,
    sendQuoteRequest,
    sendToExternalSystemAction,
    sendToCrossBorderIntegrationAction,
  ],
  [GC.LOAD_STATUS_DISPATCH_REJECTED]: [
    addDriverRateAction,
    addCarrierRateAction,
    editDriverCarrierRateAction,
    redispatchLoadAction,
    openInRBAction,
    sendQuoteRequest,
    sendToExternalSystemAction,
    sendToCrossBorderIntegrationAction,
  ],
  [GC.LOAD_STATUS_BOOKED_STATUS]: [
    callStatusCheckAction,
    checkInAction,
    completeAction,
    addTelDocumentAction,
    addAppointmentAction,
    addTelDocumentPopAction,
    getIntegrationDocumentsAction,
    openInRBAction,
    cancelDispatchedLoadAction,
    sendToExternalSystemAction,
    applyOrderRateUpliftAction,
    sendUpdateAction,
    sendToCrossBorderIntegrationAction,
  ],
  [GC.LOAD_STATUS_IN_TRANSIT]: [
    callStatusCheckAction,
    checkInAction,
    completeAction,
    addTelDocumentAction,
    addAppointmentAction,
    addTelDocumentPopAction,
    getIntegrationDocumentsAction,
    openInRBAction,
    sendToExternalSystemAction,
    applyOrderRateUpliftAction,
    sendUpdateAction,
    sendToCrossBorderIntegrationAction,
  ],
  [GC.LOAD_STATUS_DELIVERED]: [
    addDriverCarrierInvoiceAction,
    addTelDocumentPodAction,
    getIntegrationDocumentsAction,
    openInRBAction,
    sendToExternalSystemAction,
    applyOrderRateUpliftAction,
    sendToCrossBorderIntegrationAction,
  ],
};

const getLoadStatusActions = (loadType: string, status: string) => {
  const actionsMap = ifElse(R.equals(loadType, GC.LOAD_TYPE_TEL), telStatusActionsMap, cloStatusActionsMap);

  return R.compose(
    R.indexBy(R.prop(GC.FIELD_VALUE)),
    R.map((action: Object) => R.mergeRight(
      action,
      {
        bgColor: getTheme(action.bgColor),
        [GC.FIELD_DISPLAYED_VALUE]: getWindowLocale(...action.locale),
      },
    )),
    R.prop(status),
  )(actionsMap);
};

const getLoadStatusActionOptions = (loadType: string, status: string) => {
  const actionsMap = ifElse(R.equals(loadType, GC.LOAD_TYPE_TEL), telStatusActionsMap, cloStatusActionsMap);

  return R.compose(
    R.map((action: Object) => R.assoc('label', getWindowLocale(...action.locale), action)),
    R.prop(status),
  )(actionsMap);
};

const getLoadCustomStatusColor = ({ warningLevel }: Object) => {
  const loadCustomStatusMap = {
    Warning: 'colors.warning',
    Critical: 'colors.light.mainRed',
  };

  const color = R.pathOr('colors.greyMatterhorn', [warningLevel], loadCustomStatusMap);

  return getTheme(color);
};

const getStopEarlyDate = (stop: Object) => {
  if (isNotNilAndNotEmpty(R.prop(GC.FIELD_LOAD_APPOINTMENT_DATE, stop))) {
    return `${R.prop(GC.FIELD_LOAD_APPOINTMENT_DATE, stop)} ${R.prop(GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME, stop)}`;
  }

  return R.prop(GC.FIELD_LOAD_EVENT_EARLY_DATE, stop);
};

const getStopLateDate = (stop: Object) => {
  if (isNotNilAndNotEmpty(R.prop(GC.FIELD_LOAD_APPOINTMENT_DATE, stop))) {
    return `${R.prop(GC.FIELD_LOAD_APPOINTMENT_DATE, stop)} ${
      R.propOr(R.prop(GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME, stop), GC.FIELD_LOAD_APPOINTMENT_LATE_TIME, stop)}`;
  }

  return R.prop(GC.FIELD_LOAD_EVENT_EARLY_DATE, stop);
};

const getEventEarlyDateTime = (event: Object) => {
  const { earlyDate, eventEarlyDate } = event;

  if (isNotNilAndNotEmpty(earlyDate)) return getDateTimeByConfigFormat(earlyDate);

  return getDateTimeByConfigFormat(eventEarlyDate);
};

const getEventLateDateTime = (event: Object) => {
  const { lateDate, eventLateDate } = event;

  if (isNotNilAndNotEmpty(lateDate)) return getDateTimeByConfigFormat(lateDate);

  return getDateTimeByConfigFormat(eventLateDate);
};

const getEventEarlyTime = (event: Object) => {
  const { earlyDate, eventEarlyDate } = event;

  if (isNotNilAndNotEmpty(earlyDate)) return getTimeByConfigFormat(earlyDate);

  return getTimeByConfigFormat(eventEarlyDate);
};

const getEventLateTime = (event: Object) => {
  const { lateDate, eventLateDate } = event;

  if (isNotNilAndNotEmpty(lateDate)) return getTimeByConfigFormat(lateDate);

  return getTimeByConfigFormat(eventLateDate);
};

export {
  isStopDrop,
  getStopData,
  isStopPickup,
  isLoadTypeTel,
  getLoadColors,
  isLoadTypeClo,
  getStopLateDate,
  renderStopColor,
  isEventTypeDrop,
  getStatusOptions,
  getStopDatesInfo,
  formatEventDates,
  getEventLateTime,
  getStopEarlyDate,
  getStopFieldsInfo,
  getDataFromEvents,
  isEventTypePickup,
  createStopOptions,
  getEventEarlyTime,
  getStopNumberTitle,
  mapTelEventsForMap,
  isStopTypeTerminal,
  hasEventContainers,
  getLoadEventOptions,
  getLoadStopsGeodata,
  getEventLateDateTime,
  getLoadStatusActions,
  getEventEarlyDateTime,
  mapStopsWithDistances,
  hasLoadEventsContainer,
  getLoadCustomStatusColor,
  getDateTimeByConfigFormat,
  getDistancePropByLoadType,
  getLoadStatusActionOptions,
  getEventIndexPropByLoadType,
  setEventLateTimeFromLateDate,
  getItemsFromEventsUniqByGuid,
  createEventIntegrationString,
  setEventLateTimeFromLateDate2,
  setEventEarlyTimeFromEarlyDate,
  removeCLODistanceFromTelEvents,
  getStopAppointmentDateTimeInfo,
  setEventEarlyTimeFromEarlyDate2,
  createAppointmentDateTimeString,
  setEventInitialDroppedContainers,
  removeCLODistanceFromNewTelEvents,
  getContainersFromEventsUniqByGuid,
  getStatusOptionsWithoutEmptyOption,
  getBranchDropDateTimeDefaultValues,
  getBranchPickupDateTimeDefaultValues,
  getBranchNewStopDateTimeDefaultValues,
  setEventEarlyLateTimeFromEarlyLateDate,
  setEventEarlyLateTimeFromEarlyLateDate2,
  getStopPointsDataAccForDistanceCalculator,
};
