import {
  add,
  differenceInDays,
  differenceInYears,
  differenceInMonths,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';

// Parse the ISO duration string into a plain object
const parseISODuration = (isoDuration: string) => {
  const durationRegex = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+\.?\d*)S)?/;
  const match = isoDuration.match(durationRegex);

  if (!match) throw new Error('Invalid ISO duration format');

  const mapped = match.map((value: string) => parseFloat(value) || 0);

  const [, years = 0, months = 0, days = 0, hours = 0, minutes = 0, seconds = 0] = mapped;

  return { years, months, days, hours, minutes, seconds };
};

const normalizeDuration = ({ years, months, days, hours, minutes, seconds }: Object) => {
  const startDate = new Date(0);

  const endDate = add(startDate, {
    days,
    years,
    hours,
    months,
    minutes,
    seconds,
  });

  const newYears = differenceInYears(endDate, startDate);
  const newMonths = differenceInMonths(endDate, add(startDate, { years: newYears }));
  const newDays = differenceInDays(endDate, add(startDate, { years: newYears, months: newMonths }));
  const newHours = differenceInHours(endDate, add(startDate, { years: newYears, months: newMonths, days: newDays }));

  const newMinutes = differenceInMinutes(
    endDate,
    add(startDate, { years: newYears, months: newMonths, days: newDays, hours: newHours }),
  );

  const newSeconds = differenceInSeconds(
    endDate,
    add(startDate, { years: newYears, months: newMonths, days: newDays, hours: newHours, minutes: newMinutes }),
  );

  return {
    days: newDays,
    hours: newHours,
    years: newYears,
    months: newMonths,
    minutes: newMinutes,
    seconds: newSeconds,
  };
};

const roundDuration = ({ years, months, days, hours, minutes, seconds }: Object) => {
  if (seconds >= 45 && (seconds >= 30 || minutes > 0)) minutes += 1;

  if (minutes >= 45 || (minutes >= 30 && hours > 0)) {
    hours += 1;
    minutes = 0;
  }

  if (hours >= 24 || (hours >= 12 && days > 0)) {
    days += 1;
    hours = 0;
  }

  if (days >= 30 || (days >= 15 && months > 0)) {
    months += 1;
    days = 0;
  }

  if (months >= 12) {
    years += 1;
    months = 0;
  }

  return { years, months, days, hours, minutes, seconds };
};

const humanizeDuration = ({ years, months, days, hours, minutes, seconds }: Object) => {
  const roundedDuration = roundDuration({ years, months, days, hours, minutes, seconds });

  const {
    days: roundedDays,
    hours: roundedHours,
    years: roundedYears,
    months: roundedMonths,
    minutes: roundedMinutes,
  } = roundedDuration;

  const items = [];

  if (roundedYears) items.push(`${roundedYears} ${roundedYears === 1 ? 'year' : 'years'}`);

  if (roundedMonths) items.push(`${roundedMonths} ${roundedMonths === 1 ? 'month' : 'months'}`);

  if (roundedDays) items.push(`${roundedDays} ${roundedDays === 1 ? 'day' : 'days'}`);

  if (roundedHours) items.push(`${roundedHours} ${roundedHours === 1 ? 'hour' : 'hours'}`);

  if (roundedMinutes) items.push(`${roundedMinutes} ${roundedMinutes === 1 ? 'minute' : 'minutes'}`);

  return items[0] || 'a few seconds';
};

export {
  parseISODuration,
  humanizeDuration,
  normalizeDuration,
};
