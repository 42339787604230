import * as R from 'ramda';
import {
  add,
  sub,
  set,
  parse,
  format,
  subDays,
  addDays,
  isValid,
  isAfter,
  addYears,
  subWeeks,
  isBefore,
  getHours,
  subYears,
  addHours,
  subHours,
  endOfDay,
  formatISO,
  addMonths,
  subMonths,
  isSameDay,
  getMinutes,
  addMinutes,
  subMinutes,
  startOfDay,
  startOfMonth,
  startOfISOWeek,
  differenceInDays,
  intervalToDuration,
  formatDistanceToNow,
  differenceInMinutes,
  differenceInMilliseconds,
} from 'date-fns';
// constants
import * as GC from '../constants';
// helpers
import { toNumber } from './calc';
import { isPlural } from './string';
import { getAmousConfigByNameFromWindow } from './window';
import {
  parseISODuration,
  humanizeDuration,
  normalizeDuration,
} from './duration';
import {
  ifElse,
  isNumber,
  isString,
  isAllTrue,
  notEquals,
  isNilOrZero,
  isNilOrEmpty,
  isNotNilAndNotEmpty,
} from './helpers';
//////////////////////////////////////////////////

// const format = () => {};

const getCurrentDate = () => new Date();

const getCurrentDay = () => format(new Date(), GC.DEFAULT_DATE_FORMAT);

const getCurrentDayWithTime = () => format(new Date(), GC.DEFAULT_DATE_TIME_FORMAT);

const getNewDate = (item: any) => new Date(item);

const isDateInstance = (item: any) => item instanceof Date;

const getCurrentDateWithFormat = (dateFormat: string = GC.DEFAULT_DATE_FORMAT) => format(new Date(), dateFormat);

const isValidDate = (item: string) => (
  isNotNilAndNotEmpty(item) ? isValid(new Date(item)) : false
);

const isValidDateWithFormat = (item: string, dateFormat: string) => isValid(parse(item, dateFormat, new Date()));

const getNowTimeDateObject = () => set(new Date(), { hours: 0, minutes: 0 });

const dateAddYearsFromCurrent = (years: number) => add(new Date(), { years });

const dateSubtractYearsFromCurrent = (years: number) => sub(new Date(), { years });

// check this
const getDateRange = (firstDate: string, secondDate: string, dateFormat: string = 'ms') => {
  const dateRange = [new Date(firstDate), new Date(secondDate)];

  const functionsMap = {
    d: differenceInDays,
    m: differenceInMinutes,
    ms: differenceInMilliseconds,
  };

  return R.propOr(differenceInMilliseconds, dateFormat, functionsMap)(...dateRange);
};

// check this
const isSameDate = (firstDate: string, secondDate: string) => isSameDay(new Date(firstDate), new Date(secondDate));
// year month week isoWeek day hour minute second - available param

// check this
const isAfterDate = (firstDate: string, secondDate: string) => isAfter(new Date(firstDate), new Date(secondDate));

const isBeforeDate = (firstDate: string, secondDate: string) =>
  isBefore(new Date(firstDate), new Date(secondDate));

const isBetween = (date: string, firstDate: string, secondDate: string, inclusion: boolean) => {
  const dt = new Date(date);
  const start = new Date(firstDate);
  const end = new Date(secondDate);
  const isInRange = isAfter(dt, start) && isBefore(dt, end);

  return inclusion ? isInRange || isSameDay(dt, start) || isSameDay(dt, end) : isInRange;
};

const toMidday = (date: string) => set(new Date(date), { hours: 12, minutes: 0, seconds: 0 });

const isMidnight = (date: string) => {
  const dateTime = format(new Date(date), 'MM:dd:yyyy HH:mm');

  return R.and(R.equals(getHours(dateTime), 0), R.equals(getMinutes(dateTime), 0));
};

const fromNow = (item: string) => {
  const date = formatDistanceToNow(new Date(item), { addSuffix: true });

  return R.replace(/about/g, 'in', date);
};

const getStartOfMonthDay = () => format(startOfMonth(new Date()), GC.DEFAULT_DATE_FORMAT);

const addDateTime = (item: interval, interval: number = 0, type: string = 'hours') => {
  if (R.not(isValidDate(item))) return item;

  const date = new Date(item);

  const map = {
    days: addDays,
    hours: addHours,
    years: addYears,
    months: addMonths,
    minutes: addMinutes,
  };

  return R.propOr(addHours, type, map)(date, interval);
};

const subtractDateTime = (item: interval, interval: number = 0, type: string = 'hours') => {
  if (R.not(isValidDate(item))) return item;

  const date = new Date(item);

  const map = {
    days: subDays,
    hours: subHours,
    years: subYears,
    months: subMonths,
    minutes: subMinutes,
  };

  return R.propOr(subHours, type, map)(date, interval);
};

const addDateTimeWithFormat = (
  item: interval,
  interval: number = 0,
  type: string = 'hours',
  dateFormat: string = GC.DEFAULT_DATE_TIME_FORMAT,
) => {
  if (isValidDate(item)) return format(addDateTime(item, interval, type), dateFormat);

  return item;
};

const subtractDateTimeWithFormat = (
  item: interval,
  interval: number = 0,
  type: string = 'hours',
  dateFormat: string = GC.DEFAULT_DATE_TIME_FORMAT,
) => {
  if (isValidDate(item)) return format(subtractDateTime(item, interval, type), dateFormat);

  return item;
};

const convertInstanceToLocalTime = (item: Object) => (
  isValidDate(item) ? format(new Date(item), 'h:mm aa') : item
);

const convertInstanceToISOString = (item: Object) => formatISO(new Date(item));

const createLocalDateTimeFormat = (dateFormat: string) => `${dateFormat} h:mm aa`;

const convertInstanceToDefaultDateFormat = (item: Object) => format(
  isValidDate(item) ? new Date(item) : new Date(),
  GC.DEFAULT_DATE_FORMAT,
);

const convertInstanceToDefaultUserFormat = (item: Object) => (
  isValidDate(item) ? format(new Date(item), GC.DEFAULT_USER_FORMAT) : item
);

const convertInstanceToDefaultDateTimeFormat = (item: Object) => format(new Date(item), GC.DEFAULT_DATE_TIME_FORMAT);

const createTodayLocalDateTimeString = (dateFormat: string, timeValue: string) => (
  `${format(new Date(), dateFormat)} ${timeValue}`
);

const createLocalDateTimeFromInstanceOrISOString = (item: any, dateFormat: string = GC.DEFAULT_DATE_FORMAT) => (
  isValidDate(item) ? format(new Date(item), dateFormat) : item
);

const toEndOfDayFromDate = (item: any) => (
  isValidDate(item) ? format(endOfDay(new Date(item)), GC.DEFAULT_DATE_TIME_FORMAT) : item
);

const toStartOfDayFromDate = (item: any) => (
  isValidDate(item) ? format(startOfDay(new Date(item)), GC.DEFAULT_DATE_TIME_FORMAT) : item
);

const checkAndConvertMomentInstanceToFormattedDate = (item: any, format: string) => {
  if (isDateInstance(item)) {
    return createLocalDateTimeFromInstanceOrISOString(item, format);
  }

  return item;
};

const checkAndConvertStringToFormattedDate = (item: any, format: string) => {
  if (isString(item)) {
    return createLocalDateTimeFromInstanceOrISOString(item, format);
  }

  return item;
};

const checkAndConvertMomentInstanceOrStringToFormattedDate = (item: any, format: string) => {
  if (R.or(isString(item), isDateInstance(item))) {
    return createLocalDateTimeFromInstanceOrISOString(item, format);
  }

  return item;
};

const setMaxDate = (props: Object, field: Object) => {
  if (R.and(
    notEquals(field.type, 'calendar'),
    notEquals(field.type, 'datePicker'),
  )) return null;

  if (isNotNilAndNotEmpty(field.maxDate)) return field.maxDate;

  if (R.and(
      isNotNilAndNotEmpty(field.maxDateField),
      isNotNilAndNotEmpty(R.path(['formValues', field.maxDateField], props)),
    )) {
    return getNewDate(props.formValues[field.maxDateField]);
  }

  return null;
};

const setMinDate = (props: Object, field: Object) => {
  if (R.and(
    notEquals(field.type, 'calendar'),
    notEquals(field.type, 'datePicker'),
  )) return null;

  if (isNotNilAndNotEmpty(field.minDate)) return field.minDate;

  if (R.and(
      isNotNilAndNotEmpty(field.minDateField),
      isNotNilAndNotEmpty(R.path(['formValues', field.minDateField], props)),
    )) {
    return getNewDate(props.formValues[field.minDateField]);
  }

  return null;
};

const getFormattedDurationFromString = (str: string, humanize: boolean) => {
  const dur = parseISODuration(str);

  if (humanize) {
    const normalized = normalizeDuration(dur);

    return humanizeDuration(normalized);
  }

  return `${dur.days} days ${dur.hours} hours ${dur.minutes} mins`;
};

const getIntervalToDuration = (start: string, end: string) => {
  const { days, hours } = intervalToDuration({ start, end });

  if (R.equals(days, 0)) return `${hours} ${isPlural(hours, 'hour')}`;

  if (R.equals(hours, 0)) return `${days} ${isPlural(days, 'day')}`;

  return `${days} ${isPlural(days, 'day')}  ${hours} ${isPlural(hours, 'hour')}`;
};

const getDateTimeFormat = (timeSelection: boolean) => (
  ifElse(
    R.equals(timeSelection, true),
    GC.DEFAULT_DATE_TIME_FORMAT,
    GC.DEFAULT_DATE_FORMAT,
  )
);

const getDateTimeFormatFromConfig = (formatType: string = 'dateTime') => {
  const dateTimeFormatConfig = getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DATE_TIME_FORMAT);

  const formatDataObject = R.pathOr(
    R.prop(GC.DATE_TIME_FORMAT_US, GC.dateTimeFormatMap),
    [dateTimeFormatConfig],
    GC.dateTimeFormatMap,
  );

  return R.path([formatType, 'format'], formatDataObject);
};

const getDateTimeFormatForMui = (timeSelection: boolean) => {
  const timeFormat = getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DATE_TIME_FORMAT);
  const ampm = R.equals(timeFormat, GC.DATE_TIME_FORMAT_US);
  const format = ifElse(
    R.equals(timeSelection, true),
    ifElse(
      ampm,
      GC.DATE_TIME_FORMAT_AM_PM_UPPERCASE,
      GC.DEFAULT_US_MILITARY_DATE_TIME_FORMAT,
    ),
    GC.DEFAULT_DATE_FORMAT,
  );

  return {
    ampm,
    format,
  };
};

const getSplittedDateTime = (value: any) => {
  if (R.and(isNotNilAndNotEmpty(value), isValidDate(value))) {
    const splitted = R.split(' ', value);
    const date = R.head(splitted);
    const time = R.nth(1, splitted);
    const ampm = R.nth(2, splitted);

    return { date, time, ampm };
  }

  return value;
};

const setTimeToDateTime = (value: any, time: string) => {
  if (isAllTrue(isNotNilAndNotEmpty(value), isNotNilAndNotEmpty(time), isValidDate(value))) {
    const splitted = R.split(' ', value);
    const date = R.head(splitted);

    const newValue = R.toUpper(`${date} ${time}`);

    if (isValidDate(newValue)) return newValue;

    return value;
  }

  return value;
};

const convertTimeToFormat = (timeString: string, format: string = GC.DEFAULT_TIME_FORMAT) => {
  if (isNilOrEmpty(timeString)) return timeString;

  return checkAndConvertStringToFormattedDate(
    `${getCurrentDay()} ${timeString}`,
    format,
  );
};

const getFormattedOperationHoursFromStop = ({ operationHour }: Object) => {
  if (isNilOrEmpty(operationHour)) return operationHour;

  return R.map(
    (entity: Object) => R.mergeRight(entity, {
      [GC.FIELD_TIME_TO]: convertTimeToFormat(
        R.path([GC.FIELD_TIME_TO], entity),
        GC.DEFAULT_TIME_FORMAT,
      ),
      [GC.FIELD_TIME_FROM]: convertTimeToFormat(
        R.path([GC.FIELD_TIME_FROM], entity),
        GC.DEFAULT_TIME_FORMAT,
      ),
    }),
    operationHour,
  );
};

const convertTimeToConfigFormat = (time: string) => {
  const format = getDateTimeFormatFromConfig('time');

  return convertTimeToFormat(time, format);
};

const convertDateTimeToConfigFormat = (dateTime: string) => {
  const format = getDateTimeFormatFromConfig();

  return checkAndConvertMomentInstanceOrStringToFormattedDate(dateTime, format);
};

const convertOperationHoursToConfigFormat = R.map((entity: Object) => ({
  ...entity,
  [GC.FIELD_TIME_TO]: convertTimeToConfigFormat(R.prop(GC.FIELD_TIME_TO, entity)),
  [GC.FIELD_TIME_FROM]: convertTimeToConfigFormat(R.prop(GC.FIELD_TIME_FROM, entity)),
}));

const convertOperationHoursToDefaultFormat = R.map((entity: Object) => ({
  ...entity,
  [GC.FIELD_TIME_TO]: convertTimeToFormat(R.prop(GC.FIELD_TIME_TO, entity)),
  [GC.FIELD_TIME_FROM]: convertTimeToFormat(R.prop(GC.FIELD_TIME_FROM, entity)),
}));

const convertMinutesToHoursAndMinutes = (min: number) => {
  const defaultObject = {
    hours: 0,
    minutes: 0,
    shortString: '0h 0m',
    longString: '0 hour(s) 0 minute(s)',
  };

  if (isNilOrZero(min)) return defaultObject;

  const minNumber = toNumber(min);

  if (R.not(isNumber(minNumber))) return defaultObject;

  const hours = R.divide(minNumber, 60);
  const rHours = Math.floor(hours);
  const minutes = R.multiply(R.subtract(hours, rHours), 60);
  const rMinutes = Math.round(minutes);

  return {
    hours: rHours,
    minutes: rMinutes,
    shortString: `${rHours}h ${rMinutes}m`,
    longString: `${rHours} hour(s) ${rMinutes} minute(s)`,
  };
};

const getModuloFromHours = (hours: number, rHours: number) => {
  if (R.lt(hours, 1)) return hours;

  return R.modulo(hours, rHours);
};

const convertHoursToHoursAndMinutes = (h: any) => {
  const defaultObject = {
    hours: 0,
    minutes: 0,
    shortString: '0h 0m',
    longString: '0 hour(s) 0 minute(s)',
  };

  if (isNilOrZero(h)) return defaultObject;

  const hNumber = toNumber(h);

  if (R.not(isNumber(hNumber))) return defaultObject;


  const rHours = Math.floor(hNumber);
  const modulo = getModuloFromHours(hNumber, rHours);
  const minutes = R.multiply(modulo, 60);
  const rMinutes = Math.ceil(minutes);

  return {
    hours: rHours,
    minutes: rMinutes,
    shortString: `${rHours}h ${rMinutes}m`,
    longString: `${rHours} hour(s) ${rMinutes} minute(s)`,
  };
};

const convertDateTimeToConfigTimeZone = (dateTime: string, showTimeZoneName: boolean = true) => {
  if (isNilOrEmpty(dateTime)) return dateTime;

  const twoDigitFormat = '2-digit';
  const timeZone = getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_TIMEZONE);

  return getNewDate(dateTime).toLocaleString('en-US', {
    timeZone,
    year: 'numeric',
    day: twoDigitFormat,
    hour: twoDigitFormat,
    month: twoDigitFormat,
    minute: twoDigitFormat,
    timeZoneName: ifElse(showTimeZoneName, 'short', undefined),
  });
};

const formatStopDateTimeValues = (stop: Object) => R.mergeRight(stop, {
  [GC.FIELD_LOAD_EVENT_LATE_TIME]: convertTimeToFormat(
    R.path([GC.FIELD_LOAD_EVENT_LATE_TIME], stop),
    GC.DEFAULT_TIME_FORMAT,
  ),
  [GC.FIELD_LOAD_EVENT_LATE_DATE]: checkAndConvertStringToFormattedDate(
    R.path([GC.FIELD_LOAD_EVENT_LATE_DATE], stop),
    GC.DEFAULT_DATE_TIME_FORMAT,
  ),
  [GC.FIELD_LOAD_EVENT_EARLY_TIME]: convertTimeToFormat(
    R.path([GC.FIELD_LOAD_EVENT_EARLY_TIME], stop),
    GC.DEFAULT_TIME_FORMAT,
  ),
  [GC.FIELD_LOAD_EVENT_EARLY_DATE]: checkAndConvertStringToFormattedDate(
    R.path([GC.FIELD_LOAD_EVENT_EARLY_DATE], stop),
    GC.DEFAULT_DATE_TIME_FORMAT,
  ),
  [GC.FIELD_LOAD_APPOINTMENT_DATE]: checkAndConvertStringToFormattedDate(
    R.path([GC.FIELD_LOAD_APPOINTMENT_DATE], stop),
    GC.DEFAULT_DATE_FORMAT,
  ),
  [GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]: convertTimeToFormat(
    R.path([GC.FIELD_LOAD_APPOINTMENT_LATE_TIME], stop),
    GC.DEFAULT_TIME_FORMAT,
  ),
  [GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME]: convertTimeToFormat(
    R.path([GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME], stop),
    GC.DEFAULT_TIME_FORMAT,
  ),
  [GC.FIELD_OPERATION_HOUR]: getFormattedOperationHoursFromStop(stop),
  [GC.FIELD_EARLY_DATE]: checkAndConvertStringToFormattedDate(
    R.path([GC.FIELD_EARLY_DATE], stop),
    GC.DEFAULT_DATE_TIME_FORMAT,
  ),
  [GC.FIELD_LATE_DATE]: checkAndConvertStringToFormattedDate(
    R.path([GC.FIELD_LATE_DATE], stop),
    GC.DEFAULT_DATE_TIME_FORMAT,
  ),
});

const getValuesWithConvertedDateTimeFields = ({ values, dateTimeFields }: Object) => {
  if (isNilOrEmpty(dateTimeFields)) return values;

  const getConvertedDate = (date: string) => {
    if (isValidDate(date)) return convertInstanceToDefaultDateTimeFormat(date);

    return date;
  };

  let valuesWithConvertedDateTimeFields = values;

  dateTimeFields.forEach((field: string) => {
    valuesWithConvertedDateTimeFields = R.assoc(
      field,
      getConvertedDate(R.pathOr('', [field], values)),
      valuesWithConvertedDateTimeFields,
    );
  });

  return valuesWithConvertedDateTimeFields;
};
const getDayOfThisWeekByDay = (day: string) => {
  const startOfWeek = startOfISOWeek(new Date());

  return addDays(startOfWeek, GC.momentDayNumberMap[day] - 1);
};

const getDayOfPreviousWeekByDay = (day: string) => {
  const date = subWeeks(new Date(), 1);
  const startOfWeek = startOfISOWeek(new Date(date));

  return addDays(startOfWeek, GC.momentDayNumberMap[day] - 1);
};

const getLocalTimeFromDate = (date: string | Object) => createLocalDateTimeFromInstanceOrISOString(date, 'h:mm a');

const getTimeByConfigFormat = (date: string) => {
  const format = getDateTimeFormatFromConfig('time');

  if (isValidDate(date)) {
    return createLocalDateTimeFromInstanceOrISOString(date, format);
  }

  return date;
};

export {
  fromNow,
  toMidday,
  isBetween,
  isMidnight,
  setMinDate,
  setMaxDate,
  getNewDate,
  isSameDate,
  isAfterDate,
  addDateTime,
  isValidDate,
  isBeforeDate,
  getDateRange,
  getCurrentDay,
  getCurrentDate,
  isDateInstance,
  subtractDateTime,
  getDateTimeFormat,
  setTimeToDateTime,
  getStartOfMonthDay,
  toEndOfDayFromDate,
  getSplittedDateTime,
  convertTimeToFormat,
  getLocalTimeFromDate,
  toStartOfDayFromDate,
  getNowTimeDateObject,
  getDayOfThisWeekByDay,
  getCurrentDayWithTime,
  getTimeByConfigFormat,
  isValidDateWithFormat,
  addDateTimeWithFormat,
  getIntervalToDuration,
  dateAddYearsFromCurrent,
  getDateTimeFormatForMui,
  getCurrentDateWithFormat,
  formatStopDateTimeValues,
  convertTimeToConfigFormat,
  getDayOfPreviousWeekByDay,
  createLocalDateTimeFormat,
  convertInstanceToISOString,
  subtractDateTimeWithFormat,
  convertInstanceToLocalTime,
  getDateTimeFormatFromConfig,
  dateSubtractYearsFromCurrent,
  convertHoursToHoursAndMinutes,
  convertDateTimeToConfigFormat,
  getFormattedDurationFromString,
  createTodayLocalDateTimeString,
  convertDateTimeToConfigTimeZone,
  convertMinutesToHoursAndMinutes,
  convertInstanceToDefaultUserFormat,
  getFormattedOperationHoursFromStop,
  convertInstanceToDefaultDateFormat,
  convertOperationHoursToConfigFormat,
  getValuesWithConvertedDateTimeFields,
  convertOperationHoursToDefaultFormat,
  checkAndConvertStringToFormattedDate,
  convertInstanceToDefaultDateTimeFormat,
  createLocalDateTimeFromInstanceOrISOString,
  checkAndConvertMomentInstanceToFormattedDate,
  checkAndConvertMomentInstanceOrStringToFormattedDate,
};
